@font-face {
    font-family: 'latothin';
    src: url('./fonts/lato/lato-thin.eot');
    src: url('./fonts/lato/lato-thin.eot?#iefix') format('embedded-opentype'),
         url('./fonts/lato/lato-thin.woff') format('woff'),
         url('./fonts/lato/lato-thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latolight';
    src: url('./fonts/lato/lato-light-webfont.eot');
    src: url('./fonts/lato/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/lato/lato-light-webfont.woff') format('woff'),
         url('./fonts/lato/lato-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('./fonts/lato/lato-regular-webfont.eot');
    src: url('./fonts/lato/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/lato/lato-regular-webfont.woff') format('woff'),
         url('./fonts/lato/lato-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold';
    src: url('./fonts/lato/lato-bold-webfont.eot');
    src: url('./fonts/lato/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/lato/lato-bold-webfont.woff') format('woff'),
         url('./fonts/lato/lato-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoblack';
    src: url('./fonts/lato/lato-black-webfont.eot');
    src: url('./fonts/lato/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/lato/lato-black-webfont.woff') format('woff'),
         url('./fonts/lato/lato-black-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
