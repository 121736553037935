@charset "utf-8";

@fs: 14px;
@lh: 1.2;

@normal: 'latoregular', Arial, Helvetica, sans-serif;
@light: 'latolight', Arial, Helvetica, sans-serif;

// Ревизия картинок
@rev: 3;

@import url(fonts.less);

body {
    font-family: @light;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, b, .b {
    font-family: @normal;
}

pre {
    padding: 3px;
}

code {
    font-size: 75%;
}

.checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"], .radio input[type="radio"], .radio-inline input[type="radio"] {
    top: 2px;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
    padding-right: 15px;
    padding-left: 15px;
}

/* Custom page header */
.header {
    padding-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
}
/* Make the masthead heading the same height as the navigation */
.header h2 {
    margin-top: -4px;
    margin-bottom: 0;
    line-height: 48px;
    padding-left: 58px;
    background: url(../logo_geo.png?@{rev}) 0 50% no-repeat;
}

/* Custom page footer */
.footer {
    padding-top: 19px;
    color: #777;
    border-top: 1px solid #e5e5e5;
    margin-top: 25px;
}

.container-narrow > hr {
    margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
}
.jumbotron p {
    color: #666;
}
.jumbotron .btn {
    padding: 14px 24px;
    font-size: 21px;
}

/* Supporting marketing content */
.marketing {
    margin: 40px 0;
}
.marketing p + h4 {
    margin-top: 28px;
}
.marketing .soon .label {
    font-size: 55%;
    font-weight: normal;
    position: relative;
    top: -2px;
}

.operation h3 {
    padding: 8px;
    cursor: pointer;
}
.operation h3:hover {
  opacity: 0.9;
}
.operation.get h3 {
  background-color: #edf9ff;
}
.operation.post h3 {
  background-color: #edffeb;
}
.operation h3 {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 400;
  clear: both;
}
.operation.expanded h3 {
  margin-bottom: 12px;
}
.operation h3 .description {
  font-weight: 300;
  font-size: 14px;
  color: #666;
  float: right;
  margin-right: 5px;
}
.operation.expanded h3 .description {
  display: none;
}
.operation h3 .method {
  margin-right: 5px;
  display: inline-block;
}
.operation h3 .method i {
  background-color: #0f6ab4;
  color: #fff;
  padding: 1px 5px;
  border-radius: 2px;
  font-style: normal;
}
.operation.get h3 .method i {
}
.operation.post h3 .method i {
  background-color: #10a54a;
}
.operation .details {
  display: none;
}
.operation.expanded .details {
  display: block;
}
.operation .head {
  font-size: 16px;
  font-weight: 300;
  color: #888;
}
.operation .content h4 {
  font-weight: 400;
  margin-bottom: 5px;
}

.entity-difference tr.delim {
    background: #ddd;
}

.entity-difference tr.created {
    background: #BDEAD8 !important;
}

.entity-difference td.changed {
    background: #CDEFFB;
}


/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
    /* Remove the padding we set earlier */
    .header,
    .marketing,
    .footer {
        padding-right: 0;
        padding-left: 0;
    }
    /* Space out the masthead */
    .header {
        margin-bottom: 30px;
    }
    /* Remove the bottom border on the jumbotron for visual effect */
    .jumbotron {
        border-bottom: 0;
    }

    .jumbotron h1 {
        margin-bottom: 20px;
    }

    .jumbotron p {
        margin-bottom: 25px;
    }
}